.review-page{
  .page-banner{
    padding-top: 20px;
    padding-bottom: 30px;
    @include tablet{
      padding-top: 100px;
      padding-bottom: 40px;
    }
    .banner-content{
      .title{
        color: #19191b;
        font-weight: 700;
        letter-spacing: -2.5px;
        font-size: 34px;
        line-height: 46px;
        padding-bottom: 12;
        @include tablet{
          font-size: 60px;
          line-height: 70px;
          padding-bottom: 12px;
        }
      }
      p{
        color: #696871;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
        margin-top: 5px;
      }
    }
  }

  .review-section{
    padding-bottom: 20px;
    @include tablet{
      padding-bottom: 90px;
    }
}
  .review-card {
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    min-height: 403px;
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 35px 30px 30px 35px;
    transition: .4s;
    position: relative;
    flex-direction: column;
    border: 1px solid #eae9f2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    &:before{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
      content: "";
      transition: .4s;
    }
    .review-star {
      margin-bottom: 25px;
    }
    .card-content {
      // margin-bottom: 34px;
      p {
        font-style: italic;
        color: #19191B;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
      }
    }
    .card-id {
      display: flex;
      margin-top: auto;
      align-items: center;
      .id-img {
        width: 45px;
        height: 45px;
        border-radius: 500px;
        overflow: hidden;
        text-align: center;
      }   
      .id-content {
          margin-left: 22px;
          h6 {
              color: #1F1F1F;
              font-size: 18px;
              font-weight: 600;
              letter-spacing: -0.34px;
              line-height: 28px;
              margin-bottom: -5px;
          }  
          span {
              color: #666666;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.3px;
              line-height: 28px;
          }
      }
    }
    &:hover {
      &:before{
        box-shadow: -1px 6px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.04);
    }
  }
  }

  .footer-section {
    background-color: #19191b;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #ffffff;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }

}
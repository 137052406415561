.landing-7{  
  background: #fcfdfe;  
  .header-btns{
    margin-right: 10px;
    @include mobile{
      margin-right: 15px;
    }
    @include desktops{
      margin-right: 0;
    }
    a{
        font-size: 14px!important;
        width: 120px!important;
        height: 34px!important;
        @include brk-point(370px){
          font-size: 16px !important;
          width: 141px !important;
          height: 45px !important;
        }
    }
  }

  .btn--primary{
    width: 252px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #c31a12;
    background-color: #f04037;
    color: #fff;
    display: inline-flex;
    align-items:center;
    transform: perspective(1px) translateZ(0);
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:before{
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#fff,.1);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
      -webkit-transition-property: transform;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover{
      // color: #fff;
      // border-color: #f0544c;
    }
    &:hover:before, &:focus:before,&:active:before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
   }


  .hero-area{
    padding-top:100px;
    padding-bottom:50px;
    @include tablet{
      padding-top:130px;
      padding-bottom: 60px;
    }
  }  
  .hero-image{
    img{
      max-width: 80%;
      @include brk-point(400px){
        max-width: 50%;
      }
      @include mobile-lg{
        max-width: 100%;
      }
  } 
}    
  .hero-content {

    .title {
      color: $theme-primary-heading;
      font-family: $theme-primary-font;
      font-weight: 700;
      letter-spacing: -2.81px;
      font-size: 50px;
      line-height: 56px;
      margin-bottom: 28px;
      @include tablet{
        font-size: 60px;
        line-height: 70px;
      }
    }
    p {
      color: #19191b;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
    }
    .hero-btn {
      margin-top: 38px;
    }
    .video-link {
      color: $l2-secondary-color;
      font-family: $theme-primary-font;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 12px;
      display: inline-flex;
      align-items: center;
      i {
        margin-right: 6px;
      }
    }
    @include large-desktops {
      padding-right: 20px;
    }
  }
  .hero-form{
    position: relative;
    max-width: 480px;
    .input-group{
      width: 100%;
      height: 71px;
      border-radius: 10px;
      border: 1px solid #EAE9F2;
      background-color: #FFFFFF;
      position: relative;
      i{
        position: absolute;
        top: 27px;
        left:27px;
        font-size: 19px;
        color: #cdccd9;
        margin-top: -2px;

      }
      input{
        padding-left: 63px;
        padding-right: 15px;
        width: 100%;
        border: 0;
        border-radius: 10px;
        @include brk-point(500px){
          padding-right: 140px;
        }
        &:focus{
          box-shadow: none;
          outline: none;
        }
        &::placeholder{
          color: #696871;
          font-weight: 400;
        }
      }
    }
    button{
      margin-top: 15px;
      width: 200px;
      @include tablet{
        max-width: 140px;
        font-size: 18px;
      }
      @include desktops{
        max-width: 140px;
        font-size: 19px;
      }
      @include brk-point(500px){
          position: absolute;
          margin-top: 0px;
          top: 5px;
          right: 0px;
          margin-right: 5px;
      }
    }
  }


  /*------> Review Area <------*/ 
  
  .review-area{
    background-color: #5454d4;
    padding-top: 50px;
    padding-bottom: 20px;
    @include desktops{
      padding-top: 115px;
    padding-bottom: 90px;
    }
  }
  .single-review{

    .review-star{
      margin-bottom: 17px;
      i{
        font-size: 21px;
        color: #fedc5a;
      }
    }
    p{
      color: #fff;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      font-style: italic;
    }
    .user-name{
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
    }
  }
/*------> Video Area <------*/ 
.video-area{
  position: relative;
  .video-image{
    width: 100%;
  }
  .play-button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    img{
      max-width: 80%;
      @include mobile{
        max-width: 60%;
      }
      @include mobile-lg{
        max-width: 60%;
      }
      @include tablet{
        max-width: 100%;
      }
    }
  }
}


/*------> Content Area <------*/ 
.content-section{
  padding-top: 60px;
  padding-bottom: 60px;
  @include tablet{
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @include desktops{
    padding-bottom: 60px;
  }
}
.content-section-2{
  padding-bottom: 60px;
  @include tablet{
    padding-bottom: 120px;
  }
}
.content-section-3{
  padding-bottom: 60px;
  @include tablet{
    padding-bottom: 120px;
  }
}
.content-text{
  .title{
    color: #19191b;
    font-weight: 700;
    letter-spacing: -1.88px;
    padding-bottom: 20px;
    font-size: 40px;
    line-height: 50px;
    @include mobile-lg{
      font-size: 60px;
      line-height: 70px;
    }
  }
  p{
    color: #696871;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 39px;
  }
 .content-widget{
  counter-reset: widget-counter;
   .single-widget{
     padding-left: 70px;
     position: relative;
     max-width:411px;
     margin-top: 50px;
     &:before{
        counter-increment: widget-counter;
       content: counter(widget-counter);
       position: absolute;
       left: 0;
       width: 49px;
      height: 49px;
      background-color: #5454d4;
      border-radius: 500px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;
     }
     .w-title{
      color: #19191b;
      font-size: 21px;
      font-weight: 700;
      letter-spacing: -0.66px;
      margin-bottom: 15px;
     }
     p{
      color: #696871;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
      &:last-child{
        margin-bottom: 0;
      }
     }
   }
 }
}
.content-btn{
  .btn--primary{
    width: 200px;
  }
}
.content-image{
  
  @include desktops{
    text-align: left;
  }
  img{
    transform: rotate(3deg);
    border-radius: 10px;
    max-width: 70%;
    @include brk-point(400px){
      max-width: 50%;
    }
    @include mobile-lg{
      max-width: 40%;
    }
    @include tablet{
      max-width: 33%;
    }
    @include desktops{
      margin-left: 20px;
      max-width: 100%;
    }
   
  }
}
.content-image-group{
  display: flex;
  margin-right: -15px;
  .single-image{
    display: flex;
    flex-direction: column;
    &:nth-child(2){
      padding-top: 43px;
    }
    img{
      padding-bottom:15px;
      padding-right:15px;
    }
  }
}
.content-image-3{
  
  @include desktops{
    text-align: left;
  }
  @include large-desktops{
    padding-left: 50px;
  }
  img{
    transform: rotate(-3deg);
    border-radius: 10px;
    max-width: 70%;
    @include brk-point(400px){
      max-width: 50%;
    }
    @include mobile-lg{
      max-width: 40%;
    }
    @include tablet{
      max-width: 33%;
    }
    @include desktops{
      margin-left: 20px;
      max-width: 100%;
    }
   
  }
}

/*>>>>>>>> CTA area styling <<<<<<<<<*/
.cta-section{
  background:#fcfdfe;
}
.cta-content{
  padding-top: 45px;
  padding-bottom: 45px;
  @include tablet {
  padding-top: 90px;
  padding-bottom: 100px;
  }
  // border-bottom: 1px solid #E5E5E5;
  }
  .cta-content{
  text-align: center;
  h2 {
  color: #19191b;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1.88px;
  line-height: 55px;
  margin-bottom: 15px;
  @include mobile-lg{
  font-size: 60px;
  line-height: 70px;
  }
  }
  p{
    color: #696871;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 39px;
  }
  .cta-btns{
  margin-top: 35px;
  display: flex;
  justify-content: center;
  .apple-store {
  padding: 0 9px;
  transition: .4s;
  &:hover {
    transform: translateY(-8px);
  }
  }
  .google-play {
  padding: 0 9px;
  transition: .4s;
  &:hover {
    transform: translateY(-8px);
  }
  }
  }
  }


/*>>>>>>>> Footer area styling <<<<<<<<<*/

.footer-section {
  padding: 45px 0 10px;
  background-color: #fcfdfe;
  border-top: 1px solid #EAE9F2;
  p{
    color: #19191B;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 28px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.footer-list {
  a {
    color: #19191B;
    font-family: $theme-primary-font;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 1;
    @include tablet {
      &:hover {
        color: $theme-blue-color;
      }
    }
  }
  &.list-inline{
    display: inline-flex;
    @include large-desktops{
      margin-left: 90px;
    }
    li{
      padding: 0 15px;
    }
  }
}
}